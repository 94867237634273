import { FC } from 'react';
import cn from 'classnames';
import styles from './SelectWidget.module.scss';

export const SelectWidget: FC = ({
  className,
  style,
  borderless,
  value,
  onChange,
  options,
  children,
  ...props
}) => {
  return <div
    style={style}
    className={cn(className, styles.widget, { [styles.borderless]: !!borderless })}>
    <select
      {...props}
      onChange={ev => onChange(ev.currentTarget.value)}
      value={value}>
      {options.map(([id, label]) =>
        <option key={id} value={id}>{label}</option>)}
    </select>
  </div>;
};