import styles from './GradientField.module.scss';
import { ColorWidget } from './widgets/ColorWidget';
import { numberValidator, TextWidget } from './widgets/TextWidget';

export function GradientField({ effectiveValue, onValue }) {
  let { color1, color2, angle } = effectiveValue || {};

  return <div className={styles.field}>
    <ColorWidget value={color1} onChange={color1 => onValue({ color1, color2, angle })} />
    <ColorWidget value={color2} onChange={color2 => onValue({ color1, color2, angle })} />
    <TextWidget className={styles.angle}
      validator={numberValidator()}
      type="number"
      value={angle}
      suffix="°"
      title="Angle"
      onChange={angle => onValue({ color1, color2, angle })} />
  </div>;
}
