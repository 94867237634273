import { FC, forwardRef, useEffect, useState } from 'react';
import styles from './TextWidget.module.scss';

interface NumberValidatorParams {
  min?: number;
  max?: number;
}

interface Validator {
  isValid: (s: string) => boolean;
  forceValid: (s: string) => any;
}

export function numberValidator({ min, max }: NumberValidatorParams = {}): Validator {
  return {
    isValid: s => !isNaN(parseInt(s, 10)),
    forceValid: s => {
      let v = parseInt(s, 10) || 0;
      if (max !== undefined) {
        v = Math.min(max, v);
      }
      if (min !== undefined) {
        v = Math.max(min, v);
      }
      return v;
    }
  };
}

export const TextWidget: FC = forwardRef(({ value, suffix, onChange, validator, ...props }, ref) => {
  let [enteredText, setEnteredText] = useState('');
  let [isFocused, setFocused] = useState(false);

  useEffect(() => {
    !isFocused && setEnteredText(value === null ? '' : value);
  }, [isFocused, value]);

  return <div className={styles.widget}>
    <input
      ref={ref}
      {...props}
      value={isFocused ? enteredText : value}
      onFocus={ev => {
        props.onFocus && props.onFocus(ev);
        setFocused(true);
      }}
      onBlur={ev => {
        props.onBlur && props.onBlur(ev);
        setFocused(false);
        if (validator) {
          ev.currentTarget.value = String(validator.forceValid(ev.currentTarget.value));
        }
      }}
      onChange={ev => {
        let v = ev.currentTarget.value;
        setEnteredText(v);
        onChange(validator ? validator.forceValid(v) : v);
      }} />
    {suffix && <span className={styles.suffix}>
      <span className={styles.hidden}>{isFocused ? enteredText : value}</span>
      {suffix}
    </span>}
  </div>;
});
