import { FC, Fragment, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { gaEvent, gaSetUserProperties } from '../../analytics';
import { Action, ActionPack } from '../../components/action-pack/ActionPack';
import { AnimatedIcon } from '../../components/animated-icon/AnimatedIcon';
import { Splitter } from '../../components/splitter/Splitter';
import { ALL_MODULES, DocumentContext, DocumentContextProvider } from '../../DocumentContext';
import { PropertyEditor } from '../../property-editor/PropertyEditor';
import { documentFromUrl, documentToUrl } from '../../serialization';
import { useAsyncDebouncedEffect } from '../../useAsyncDebouncedEffect';
import { useKeyboardMode } from '../../useKeyboardMode';
import { useMediaQuery } from '../../useMediaQuery';
import { useTimedConfirmation } from '../../useTimedConfirmation';
import { downloadFile, generateZip } from '../../util';
import { LogoDarkImage } from './images/logo-dark';
import { LogoLightImage } from './images/logo-light';
import { SplitterHandleImage } from './images/splitter-handle';
import DownloadCheckAnimation from './images/sprite-download-check-white@2x.png';
import styles from './MainPage.module.scss';
import { ModulePicker } from './ModulePicker';

export const MainPage: FC = (props) => {
  return <DocumentContextProvider>
    <MainPageInner {...props} />
  </DocumentContextProvider>;
};


const MainPageInner: FC = () => {
  let [darkTheme, setDarkTheme] = useState(window.localStorage.darkTheme === 'true');
  let [activeModule, setActiveModule] = useState(null);
  let context = useContext(DocumentContext);
  let { rawValues, setAllValues, set, setModules } = context;
  let [isGeneratingZip, setGeneratingZip] = useState(false);
  let [animateDownloadIcon, setAnimateDownloadIcon] = useState(false);
  let [splitSize, setSplitSize] = useState(30);
  let [isConfirmingCopy, confirmCopy, clearConfirmCopy] = useTimedConfirmation(3000);
  let isNarrow = useMediaQuery('(max-width: 599px)');

  useKeyboardMode(document.body, 'is-keyboard-mode');

  let { modules } = context;

  // path handling
  // TODO: refactor to react-router
  useLayoutEffect(() => {
    let path = document.location.pathname.replace(/^\/i\//, '');
    if (!path || path === '/') {
      return;
    }
    try {
      let { values, modules: moduleTypes } = documentFromUrl(path);
      setAllValues(values);
      let modules = moduleTypes.map(type => ALL_MODULES.find(m => m.type === type)).filter(m => !!m);
      setModules(modules);
      setTimeout(() => setActiveModule(modules[0]));
    } catch (e) {
      console.warn('Invalid path, starting from scratch.');
    }
  }, [setAllValues, setModules]);

  useAsyncDebouncedEffect(async () => {
    if (Object.keys(rawValues).length > 0) {
      let hash = `/i/${documentToUrl({
        values: rawValues,
        modules: modules.map(({ type }) => type)
      })}`;
      window.history.replaceState(null, '', hash);
    }
  }, { delay: 500 }, [rawValues, modules]);

  useLayoutEffect(() => {
    window.localStorage.darkTheme = !!darkTheme;
    document.body.classList.toggle('dark-theme', darkTheme);
    gaSetUserProperties({ dark_theme: !!darkTheme });
    set('darkTheme', darkTheme);
    gaEvent('change_theme');
  }, [darkTheme, set]);

  useEffect(() => {
    // swallow up accidental drops
    let el = document.body;
    let onDrop = ev => ev.preventDefault();
    let onDragOver = ev => ev.preventDefault();
    el.addEventListener('dragover', onDragOver);
    el.addEventListener('drop', onDrop);
    return () => {
      el.removeEventListener('dragOver', onDragOver);
      el.removeEventListener('drop', onDrop);
    }
  }, []);

  useEffect(() => {
    if (modules.length === 0) {
      setActiveModule(null);
      return;
    }

    if (!activeModule || modules.indexOf(activeModule) < 0) {
      setActiveModule(modules[0]);
    }
  }, [modules, activeModule]);

  let generateAndDownload = async () => {
    setAnimateDownloadIcon(false);
    setGeneratingZip(true);
    try {
      let m = [...modules];
      let pairs = (await Promise.all(
        m.map(m => m.generateArtifacts(context))))
        .map((a, i) => [m[i], a]);
      let allArtifacts = [];
      for (let [module, artifacts] of pairs) {
        allArtifacts = allArtifacts.concat(artifacts
          .map(a => ({ ...a, filename: `${module.type}/${a.filename}` })));
      }
      // note: JSZip handles promises in artifact content
      let zipBlob = await generateZip(allArtifacts);
      downloadFile(zipBlob, 'IconKitchen-Output.zip');
      setAnimateDownloadIcon(true);
    } finally {
      setGeneratingZip(false);
    }
  };

  return <div className={styles.page}
    style={{ '--split-size': `${splitSize}vw` }}>
    <Header darkTheme={darkTheme} />
    <Toolbar>
      <ModulePicker
        activeModule={activeModule}
        onChange={m => setActiveModule(m)} />
      <ActionPack className={styles.toolbarButtons}>
        <Action
          primary
          disabled={isGeneratingZip || !activeModule}
          onClick={() => {
            gaEvent('download', {
              targets: modules.map(({ type }) => type).join(', ')
            });
            generateAndDownload();
          }}
          icon={<AnimatedIcon
            icon="file_download"
            animation={DownloadCheckAnimation}
            playing={animateDownloadIcon}
            onStopped={() => setAnimateDownloadIcon(false)} />}
          label={isGeneratingZip ? 'Generating...' : 'Download'} />
        <Action
          icon="link"
          tooltip={isConfirmingCopy ? 'Copied!' : 'Copy link'}
          onHideTooltip={() => clearConfirmCopy()}
          disabled={!activeModule}
          onClick={() => {
            gaEvent('copy_link');
            navigator.clipboard.writeText(window.location.href);
            confirmCopy();
          }}
          overflow={isNarrow} />
        <Action
          icon={darkTheme ? 'brightness_7' : 'brightness_2'}
          tooltip={darkTheme ? 'Switch to light theme' : 'Switch to dark theme'}
          onClick={() => setDarkTheme(!darkTheme)}
          overflow={isNarrow} />
        <Action
          icon="delete_sweep"
          tooltip="Start over"
          onClick={() => window.location.href = '/'}
          overflow={true} />
      </ActionPack>
    </Toolbar>
    {activeModule && <PropertyEditor
      className={styles.propertyEditor}
      module={activeModule}>
      <footer className={styles.footer}>
        Made by <a href="https://twitter.com/romannurik" rel="noreferrer" target="_blank">@romannurik</a>
      </footer>
    </PropertyEditor>}
    <Preview module={activeModule} darkTheme={darkTheme}>
      <Splitter
        className={styles.splitter}
        storageKey="property-editor"
        thickness={24}
        min={0}
        max={50}
        onResize={splitSize => setSplitSize(splitSize)}>
        <SplitterHandleImage />
      </Splitter>
    </Preview>
  </div>;
};

const Header: FC = ({ darkTheme }) => {
  const LogoImage = darkTheme ? LogoDarkImage : LogoLightImage;
  return <header className={styles.header}>
    <LogoImage className={styles.logo} />
    <h1>IconKitchen</h1>
    <p className={styles.subtitle}>App Icon Generator</p>
  </header>;
};

const Toolbar: FC = ({ children }) => {
  return <div className={styles.toolbar}>
    {children}
  </div>;
};

const Preview: FC = ({ module, children, darkTheme }) => {
  let { previews } = useContext(DocumentContext);

  return <div className={styles.preview}>
    {children}
    {module && <>
      {!!Object.keys(previews[module.type] || {}).length && (module.renderPreview
        ? <Fragment key={module.type}>
          {module.renderPreview(previews[module.type], { darkTheme })}
        </Fragment>
        : <SimplePreview
          module={module}
          imgSrc={previews[module.type].main} />)}
    </>}
  </div>;
};

const SimplePreview: FC = ({ module, imgSrc }) => {
  return <div className={styles.simplePreview}>
    <img
      src={imgSrc}
      alt={`Preview of ${module.label} output`} />
  </div>
}
