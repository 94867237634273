import cn from 'classnames';
import { Children, FC, useState } from 'react';
import { MenuItem, PopupMenu } from '../popup-menu/PopupMenu';
import { Tooltip } from '../tooltip/Tooltip';
import styles from './ActionPack.module.scss';


export const ActionPack: FC = ({ children, className }) => {
  let [menuState, setMenuState] = useState(null);

  let childrenArray = Children.toArray(children);
  let visibleChildren = childrenArray.filter(({ props }) => !props.overflow);
  let overflowChildren = childrenArray.filter(({ props }) => !!props.overflow);

  let showOverflowMenu = ev => {
    let v = ev.currentTarget.getBoundingClientRect();
    setMenuState({
      right: window.innerWidth - v.right,
      top: v.top + v.height + 4,
    });
  };

  return <div className={cn(className, styles.pack)}>
    {visibleChildren.map(({ key, props }) => <VisibleAction key={key} {...props} />)}
    {!!overflowChildren.length && <VisibleAction
      icon="more_horiz"
      tooltip="More options"
      onClick={ev => showOverflowMenu(ev)} />}
    {menuState && <PopupMenu
      show
      onClose={() => setMenuState(null)}
      right={menuState?.right}
      top={menuState?.top}>
      {overflowChildren.map(({ key, props }) => <MenuItem key={key}
        icon={props.icon}
        label={props.label || props.tooltip}
        disabled={props.disabled}
        onClick={props.onClick} />)}
    </PopupMenu>}
  </div>;
}

const VisibleAction: FC = ({ icon, tooltip, label, primary, disabled, onClick, onHideTooltip }) => {
  let button = <button
    className={cn(styles.action, {
      [styles.isPrimary]: primary,
      [styles.hasIcon]: !!icon,
      [styles.iconOnly]: !label
    })}
    onClick={onClick}
    disabled={disabled}
    aria-label={label || tooltip || ''}>
    {icon && typeof icon === 'string' && <i className="material-icons">{icon}</i>}
    {icon && typeof icon === 'object' && icon}
    {label && <span>{label}</span>}
  </button>;

  if (!tooltip || label) {
    return button;
  }

  return <Tooltip tooltip={tooltip} onHide={() => onHideTooltip && onHideTooltip()}>
    {button}
  </Tooltip>;
};

// register-only, renders as VisibleAction or MenuItem
export const Action: FC = () => null;