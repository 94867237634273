import { FC } from 'react';
import { TextWidget } from './widgets/TextWidget';

export const TextField: FC = ({ fieldId, property, value, onValue }) => {
  return <TextWidget
    id={fieldId}
    type="text"
    onChange={value => onValue(value || null)}
    value={value || ''}
    placeholder={property.default} />
};
