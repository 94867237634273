import cn from 'classnames';
import { Children, cloneElement, FC, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useModalRootNode } from '../../useModalRootNode';
import styles from './Tooltip.module.scss';


export const Tooltip: FC = ({
  tooltip,
  className,
  onShow,
  onHide,
  children,
}) => {
  let [openState, setOpenState] = useState(null);
  let modalRoot = useModalRootNode();

  useEffect(() => {
    setOpenState(true);
  }, []);

  const showTooltip = useCallback(ev => {
    let v = ev.currentTarget.getBoundingClientRect();
    setOpenState({
      left: (v.left + v.right) / 2,
      top: v.bottom,
    });
  }, [setOpenState]);

  return <>
    {Children.map(children, child => child && cloneElement(child, {
      onMouseEnter: (event, ...args) => {
        child.props.onMouseMove && child.props.onMouseMove(event, ...args);
        showTooltip(event);
        onShow && onShow();
      },
      onMouseLeave: (event, ...args) => {
        child.props.onMouseMove && child.props.onMouseLeave(event, ...args);
        setOpenState(null);
        onHide && onHide();
      }
    }))}
    {!!openState && !!tooltip && createPortal(
      <div
        style={{ left: openState.left, top: openState.top, }}
        className={cn(className, styles.tooltip)}>
        {tooltip}
      </div>, modalRoot)}
  </>;
};
