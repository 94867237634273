import cn from 'classnames';
import styles from './BooleanField.module.scss';

export function BooleanField({ fieldId, effectiveValue, onValue }) {
  return <label className={cn(styles.field, { [styles.isChecked]: !!effectiveValue })}>
    <input
      id={fieldId}
      type="checkbox"
      onChange={ev => onValue(ev.currentTarget.checked)}
      checked={!!effectiveValue} />
  </label>;
}
