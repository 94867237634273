import { FC } from 'react';
import styles from './ImageOverlays.module.scss';
import cn from 'classnames';

export const ImageOverlays: FC = ({ style, className, src, width, height, overlays }) => {
  return <div
    style={style}
    className={cn(className, styles.container)}>
    <img
      className={styles.bg}
      src={src}
      alt=""
      style={{
        width,
        height,
      }} />
    {overlays.map(({ src, label, x, y, width, height }) =>
      <img
        key={`${x},${y},${width},${height}`}
        className={styles.previewIcon}
        src={src}
        alt={label || ''}
        style={{
          position: 'absolute',
          left: x,
          top: y,
          width,
          height,
        }} />)}
  </div>;
};