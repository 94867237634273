import cn from 'classnames';
import { FC, useState } from 'react';
import styles from './ToggleButton.module.scss';

export const ToggleButton: FC = ({ name, className, value, onChange, options, ...props }) => {
  let [randomName] = useState(`toggle-${Math.floor(Math.random() * 9999)}`);
  name = name || randomName;

  return <div
    {...props}
    className={cn(className, styles.widget)}>
    {options.map(([id, label]) => {
      let selected = id === value;
      return <label key={id} className={cn({ [styles.isSelected]: selected })}>
        <input type="radio"
          name={name}
          value={id}
          onChange={() => onChange(id)}
          checked={selected} />
        {label}
      </label>;
    })}
  </div>;
};