import JSZip from "jszip";

export function sanitizeResourceName(s) {
  return s.toLowerCase().replace(/[\s-.]/g, '_').replace(/[^\w_]/g, '');
}

export function downloadFile(content, filename) {
  let anchor = document.createElement('a');
  anchor.style.display = 'none';
  document.body.appendChild(anchor);
  let blob = content;
  if (!(content instanceof Blob)) {
    blob = new Blob([content], { type: 'application/octet-stream' });
  }
  let url = window.URL.createObjectURL(blob);
  anchor.setAttribute('href', url);
  anchor.setAttribute('download', filename);
  anchor.click();
  setTimeout(() => {
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  }, 5000);
}


export async function generateZip(artifacts: Artifact[]): Promise<Blob> {
  let zip = new JSZip();
  for (let { filename, content } of artifacts) {
    zip.file(filename, content);
  }

  return await zip.generateAsync({ type: 'blob' });
}


export function debounce(delay, fn) {
  let timeout;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      fn(...args)
      timeout = null;
    }, delay);
  };
};