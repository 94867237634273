import cn from 'classnames';
import { FC, useContext } from 'react';
import { ScrollEdges } from '../components/scroll-edges/ScrollEdges';
import { DocumentContext } from '../DocumentContext';
import { BooleanField } from './BooleanField';
import { ClipartField } from './clipart/ClipartField';
import { ColorField } from './ColorField';
import { EnumField } from './EnumField';
import { FontField } from './FontField';
import { GradientField } from './GradientField';
import { ImageField } from './ImageField';
import { PaddingField } from './PaddingField';
import styles from './PropertyEditor.module.scss';
import { TextField } from './TextField';
import { TextureField } from './TextureField';

const COMPONENT_MAP: { [type in Property['type']]: FC } = {
  'text': TextField,
  'enum': EnumField,
  'color': ColorField,
  'font': FontField,
  'clipart': ClipartField,
  'image': ImageField,
  'boolean': BooleanField,
  'padding': PaddingField,
  'texture': TextureField,
  'gradient': GradientField,
}

export const PropertyEditor: FC = ({ className, children, module }) => {
  let { values } = useContext(DocumentContext);

  if (!module) {
    return children;
  }

  return <ScrollEdges
    className={cn(className, styles.propertyEditor)}
    edgeClassNames={{ top: styles.topScrollEdge }}>
    <ScrollEdges.Content className={styles.propertyEditorContent}>
      {module.propertyModel.groups.map(({ title, noHeader, properties }) =>
        <div className={styles.group} key={title}>
          {!noHeader && <div className={styles.header}>
            <h2>{title}</h2>
          </div>}
          <div className={styles.properties}>
            {properties
              .filter(({ depends }) => !depends || depends(values))
              .map(property => <Property key={property.id} property={property} module={module} />)}
          </div>
        </div>
      )}
      {children}
    </ScrollEdges.Content>
  </ScrollEdges>;
};

const Property: FC = ({ property, module }) => {
  const { id, title, type, labelHidden } = property;
  const { values, rawValues, set } = useContext(DocumentContext);
  const FieldComponent = COMPONENT_MAP[type] || TextField;
  const fieldId = `prop-${module.type}-${id}`;
  return <div
    data-type={type}
    className={cn(styles.property, { [styles.labelHidden]: labelHidden })}>
    {!labelHidden && <label htmlFor={fieldId} className={styles.propertyLabel}>{title}</label>}
    <FieldComponent
      fieldId={fieldId}
      property={property}
      value={rawValues[id]}
      effectiveValue={values[id]}
      onValue={val => set(id, val)} />
  </div>;
};
