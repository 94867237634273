const DEBUG = process.env.NODE_ENV === 'development';

const gtag = window['gtag'];

gtag('config', 'G-B6HP98LS7F', DEBUG ? { debug_mode: true } : {});

let userProperties = {};

export function gaEvent(eventName, params = {}) {
  gtag('event', eventName, params);
}

export function gaSetUserProperties(properties) {
  userProperties = { ...userProperties, ...properties };
  gtag('set', 'user_properties', userProperties);
}
